/*Archivo para definir y crear componente*/
import React from 'react';
import '../css/Testimonios.css'

function Testimonio(props) {
    return (
        <div className='contenedor-testimonio'>

            <img
                className='imagen-testimonio'
                src={require(`../imagenes/${props.imagen}`)} alt='Imagen del testimonio'
            />

            <div className='contenedor-text-testimonio'>
                <p className='nombre-testimonio'><strong>{props.nombre}</strong> en {props.pais}</p>
                <p className='cargo-testimonio'>{props.cargo} en <strong>{props.empresa}</strong></p>
                <p className='text-testimonio'>"{props.testimonio}"</p>
            </div>
        </div>
    );
}

export default Testimonio;